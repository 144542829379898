/**
 * Sets a cookie. Default DaysToLive to 1 week.
 *
 * @param name
 * @param value
 * @param daysToLive
 * @param path
 */
function setCookie(name, value, daysToLive = 7, path = '/') {
  let maxAge = daysToLive;
  if(typeof daysToLive === 'number') {
    maxAge = (24 * 60 * 60) * daysToLive;
  }

  document.cookie = `${name}=${encodeURIComponent(value)};max-age=${maxAge};path=${path}`;
}

/**
 * Get a cookie from cookie list.
 * @param name
 * @returns {string|null}
 */
function getCookie(name) {
  let cookieArr = document.cookie.split(";");
  for(let i = 0; i < cookieArr.length; i++) {
    let cookiePair = cookieArr[i].split("=");

    if(name === cookiePair[0].trim()) {
      let value = decodeURIComponent(cookiePair[1].trim());

      if(value === 'undefined') {
        return null;
      }

      return value;
    }
  }

  return null;
}


/**
 * Deletes a cookie by setting the expiration date to a date in the past
 *
 * @param name
 * @param path
 */
function deleteCookie(name, path = "/") {
  // set a time in the past
  // let time_in_past =  new Date('2000-01-01');

  // set the max-age of cookie to time in the past. This effectively remove the cookie
  setCookie(name, false, 0, path);
}

export { setCookie, getCookie, deleteCookie };
